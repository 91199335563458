<template>
    <v-dialog v-model="isVisible" persistent light max-width="950">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="boxed-icon" tile elevation="0">
                    {{ $t('global.create') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('dashboard_inspiration_add.headline') }}</v-card-title>
            <v-card-text class="tiemposText mt-4">{{ $t('dashboard_inspiration_add.explanation') }}</v-card-text>
            <v-card-text>
                <v-form ref="form" v-model="formValid">
                    <v-row>
                        <v-col cols="6" class="py-0">
                            <v-text-field :placeholder="$t('dashboard_inspiration_add.name_placeholder')" :label="$t('dashboard_inspiration_add.name_label')" :rules="ruleNotEmpty" v-model="name"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field :placeholder="$t('dashboard_inspiration_add.orderby_placeholder')" :label="$t('dashboard_inspiration_add.orderby_label')" v-model="orderBy"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-select :items="collections" :label="$t('dashboard_inspiration_add.filter_collection')" :rules="ruleNotEmpty" v-model="collection"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-select :items="subcollections" :label="$t('dashboard_inspiration_add.filter_subcollection')" v-model="subcollection" :rules="ruleNoEmptySubcollection" :disabled="subcollections.length <= 1"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-select :items="surfaces" :label="$t('dashboard_inspiration_add.filter_surface')" v-model="surface"/>
                        </v-col>
                    </v-row>

                    <v-card elevation="2" class="px-4 pb-4">
                        <v-tabs class="mt-4" light color="primary" v-model="tab">
                            <v-tab v-for="file in files" :key="file.type">{{ labelFileTab(file.nr, file.brickColor) }}</v-tab>
                        </v-tabs>

                        <v-tabs-items light v-model="tab">
                            <v-tab-item class="pt-2" v-for="file in files" :key="file.type">
                                <v-row>
                                    <v-col cols="2" v-if="showImagePreview(file)">
                                        <v-img class="image__preview" :lazy-src="file.data.lazy" :src="file.data.thumb" @click="$popupImage(file.data)"/>
                                    </v-col>
                                    <v-col :cols="showImagePreview(file) ? 5 : 6">
                                        <v-file-input v-model="file.value" truncate-length="50" show-size :label="file.title" :placeholder="$t('dashboard_inspiration_add.upload_file_placeholder', [file.title])" :rules="uploadRules"/>
                                    </v-col>
                                    <v-col :cols="showImagePreview(file) ? 5 : 6">
                                        <v-select v-model="file.brickColor" :items="brickColors" :label="$t('dashboard_inspiration_add.filter_color')"/>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn text class="mr-4 mb-2" @click="isVisible = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn v-if="this.inspirationId" :disabled="!valuesEntered || !formValid" color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" @click="updateInspiration">
                    {{ $t('global.save') }}
                    <v-icon right>mdi-content-save</v-icon>
                </v-btn>
                <v-btn v-else :disabled="!valuesEntered || !formValid" color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" @click="addInspiration">
                    {{ $t('global.create') }}
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { toggleable } from '@/mixins/toggleable';
import { BRICK_COLORS, BRICK_SURFACES, IMPORT_IMAGE_MAX_DIMENSIONS, restrictUploadSizeTo, RULES_FIELD_REQUIRED, BRICK_COLLECTIONS } from '@/utils';
import { getFirestoreInspirationSnapshot, uploadFilesAndAddFirestoreInspiration, uploadFilesAndUpdateFirestoreInspiration } from '@/firebase/firestore/inspirations';
import { imageRestrict } from '@/utils/image';

export default {
    name: 'InspirationAddDialog',

    mixins: [toggleable],

    props: {
        user: {
            type: Object,
            required: true,
        },

        inspirationId: {
            type: String,
            default: '',
        }
    },

    watch: {
        values () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },
        collection () {
            if (this.subcollection !== '') {
                this.subcollection = '';
            }
            this.updateSubcollectionList();
            this.$refs?.form?.validate();
        },
        isVisible () {
            if (this.isVisible && this.inspirationId) {
                this.populateFieldsFromInspirationId(this.inspirationId);
            }
        },
    },

    data: function () {
        return {
            tab: 0,
            formValid: null,

            name: null,
            orderBy: null,
            collection: null,
            subcollection: null,
            surface: null,

            nrOfFiles: 7,
            files: [],
            brickColors: [],
            collections: [],
            subcollections: [],
            surfaces: [],

            uploadRules: [
                file => restrictUploadSizeTo(50)[0](file),
            ],
            ruleNotEmpty: [
                ...RULES_FIELD_REQUIRED,
            ],
            ruleNoEmptySubcollection: [
                v => this.subcollections.length <= 1 || RULES_FIELD_REQUIRED[0](v),
            ],
        };
    },

    computed: {
        /**
         * Schakel verstuurknop in zodra alle files toegevoegd zijn
         * @return {boolean}
         */
        valuesEntered () {
            return (!!this.files[0].value || this.files[0].exists) && !!this.files[0].brickColor;
        },
    },

    created () {
        this.resetForm();

        this.brickColors = [{text: this.$t('global.choose'), value: ''}, ...BRICK_COLORS, 'design'];
        this.collections = [{text: this.$t('global.choose'), value: ''}, ...BRICK_COLLECTIONS.map(collection => collection.name)];
        this.subcollections = [{text: this.$t('global.choose'), value: ''}];
        this.surfaces = [{text: this.$t('global.choose'), value: ''}, ...BRICK_SURFACES];
    },

    methods: {
        resetForm () {
            this.tab = 0;
            this.name = '';
            this.orderBy = '';
            this.files = [
                ...[...Array(this.nrOfFiles).keys()].map(nr => ({
                    nr: nr + 1,
                    title: nr === 0 ? this.$t('dashboard_inspiration_add.file_main') : this.$t('dashboard_inspiration_add.file_input', [nr + 1]),
                    value: null,
                    brickColor: '',
                    // Bestaande data, kan overschreven worden en toont tumbnail
                    exists: false,
                    data: null,
                })),
            ];
            this.collection = '';
            this.subcollection = '';
            this.surface = '';
        },

        /**
         * Geeft label terug voor het tab op basis van nr en inhoud
         * @param {Number} nr - Nr van bestand (beginnend bij 1)
         * @param {String} brickColor
         */
        labelFileTab (nr, brickColor) {
            if (brickColor !== '') {
                return brickColor;
            }

            return nr === 1 ? this.$t('dashboard_inspiration_add.tab_label_main') : this.$t('dashboard_inspiration_add.tab_label_file', [nr]);
        },

        /**
         * Bepaal of er een thumbnail getoond kan worden
         */
        showImagePreview (file) {
            return file.exists && file.data?.thumb && !file.value && file.brickColor;
        },

        /**
         * Stel alle data samen die in een inspiratie-item hoort
         * @return {Array} - Eerste item is files, tweede is metadata
         */
        async _compileInspirationData () {
            const files = [];
            const brickColors = [];
            for (let i = 0; i < this.files.length; i++) {
                if (!this.files[i].exists && !this.files[i].value) {
                    continue;
                }

                // Verwijdert ook bestaande bestanden uit de lijst
                if (!this.files[i].value && !this.files[i].brickColor) {
                    continue;
                }

                if (this.files[i].value) {
                    // Nieuwe afbeelding
                    const file = await imageRestrict(this.files[i].value, {thresholdFileSize: 5 * 1024 * 1024, thresholdDimensions: IMPORT_IMAGE_MAX_DIMENSIONS});
                    file.meta = {/* behouden metadata: ...this.files[i].data.meta, */brickColor: this.files[i].brickColor};
                    files.push(file);
                } else {
                    // Bestaande afbeelding gebruiken, maar eventueel de kleur overschrijven
                    const file = this.files[i].data;
                    file.meta = {brickColor: this.files[i].brickColor};
                    files.push(file);
                }

                brickColors.push(this.files[i].brickColor);
            }

            return [
                files,
                {
                    orderBy: this.orderBy,
                    brickColors,
                    surface: this.surface,
                    collection: this.collection.toLowerCase(),
                    subcollection: this.subcollection.toLowerCase(),
                },
            ];
        },

        /**
         * Voeg een rij toe aan de inspiraties
         */
        async addInspiration () {
            const [files, meta] = await this._compileInspirationData();
            await uploadFilesAndAddFirestoreInspiration(this.name, files, meta, this.user);

            this.resetForm();
            this.isVisible = false;

            this.$emit('added');
        },

        /**
         * Update een item van de inspiraties
         */
        async updateInspiration () {
            const [files, meta] = await this._compileInspirationData();
            await uploadFilesAndUpdateFirestoreInspiration(this.inspirationId, this.name, files, meta);

            this.resetForm();
            this.isVisible = false;

            this.$emit('updated');
        },

        /**
         * Werk de lijst van subcollecties bij aan de hand van de gekozen collecties
         */
        updateSubcollectionList () {
            if (!this.collection) {
                this.subcollections = [this.subcollections[0]];
                return;
            }

            this.subcollections = [
                this.subcollections[0],
                ...BRICK_COLLECTIONS
                    // Zoek de collectie met huidige naam
                    .find(collection => collection.name === this.collection)
                    // Voeg daarvan de subcollecties toe aan de lijst
                    .subcollections,
            ];
        },

        /**
         * Vul velden in op basis van bestaande inspiratie in Firebase
         * @param docId
         */
        async populateFieldsFromInspirationId (docId) {
            const snapInspiration = await getFirestoreInspirationSnapshot(docId);
            if (!snapInspiration) {
                return false;
            }

            this.populateFieldsFromObject(snapInspiration.data());
        },

        /**
         * Vul velden in op basis van een object met de informatie van 1
         * inspiratie-item.
         * @param data
         */
        populateFieldsFromObject (data) {
            this.name = data.name;
            this.orderBy = data.orderBy;
            this.collection = data.collection;
            this.$nextTick(() => this.subcollection = data.subcollection);
            this.surface = data.surface;

            data.files.forEach((file, index) => {
                this.files[index].exists = true;
                this.files[index].data = file;
                this.files[index].brickColor = file.meta.brickColor;
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables';

.v-list-item:nth-of-type(even) {
    background-color: $colors-grey-lighten5;
}

.image__preview {
    cursor: pointer;
}
</style>
