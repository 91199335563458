<template>
    <v-row no-gutters dense>
        <v-col cols="4" class="left-sidebar px-10 py-8">
            <v-card light class="mt-4 pa-3" elevation="0">
                <h1 class="text-h1 pt-8">{{ $t('dashboard.heading') }}</h1>
                <v-row class="pl-1 ma-0">
                    <v-col cols="12" class="mainmenu">
                        <a class="overline"><!-- @click="$router.push('/inspirational-designs')" -->
                            <v-icon x-small color="primary">mdi-arrow-right</v-icon>
                            Inspirational designs
                        </a>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="8" class="px-12 py-2 background-gradient">
            <v-card dark class="mt-12 pa-5" style="min-height: 70px" outlined tile>
                <v-card-title class="headline">
                    Inspirational designs
                </v-card-title>
                <v-card-text class="tiemposText mt-5">
                    <inspirational-design-list-tabular :designs="designs" show-image @updated="resetSearch"/>
                </v-card-text>
                <v-card-actions>
                    <book-navigation
                        :has-previous="designs.length && hasPrev" @previous="prevPage(designs[0])"
                        :has-next="designs.length && hasNext" @next="nextPage(designs[designs.length - 1])"
                    />
                    <v-spacer/>
                    <inspiration-add-dialog v-slot="{ on, attrs }" :user="user" @added="resetSearch">
                        <v-btn v-bind="attrs" v-on="on" color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" :disabled="!$inGroupAdmin() && !$inGroupDesigners()">
                            {{ $t('global.create') }}
                            <v-icon right>mdi-plus</v-icon>
                        </v-btn>
                    </inspiration-add-dialog>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BookNavigation from '@/components/BookNavigation';
import InspirationalDesignListTabular from '@/components/InspirationalDesignListTabular';
import InspirationAddDialog from '@/components/dialogs/InspirationAddDialog';
import { refInspirations } from '@/firebase/firestore/inspirations';

export default {
    name: 'TheDashboard',

    components: {BookNavigation, InspirationAddDialog, InspirationalDesignListTabular},

    data: () => ({
        pageSize: 12,
        firstInspiration: null,
        designs: [],
        hasPrev: false,
        hasNext: false,
    }),

    computed: {
        ...mapGetters('authentication', {
            user: 'getUser',
            roles: 'getRoles',
        }),
        ...mapState('workflow', {
            workflow: 'boundInstance',
        }),
    },

    watch: {
        isVisible (value) {
            value && this.loadInspirations();
        },
    },

    mounted () {
        /** @var {Object} - Bevat de Firestore Query template voor paginering van de designs */
        this.designsPageTemplate = null;

        /** @var {Object} - Bevat de Firestore Query (inclusief cursor en limiet) voor paginering van de designs */
        this.designsPage = null;

        this.initiateInspirations();
        this.resetSearch();
    },

    methods: {
        prevPage (first) {
            this.designsPage = this.designsPageTemplate.endAt(first.$snap).limitToLast(this.pageSize + 1);
            this.loadInspirations();
        },

        nextPage (last) {
            // Neem 1 item extra (die niet getoond wordt).
            // Als er pageSize plus 1 items in de resultaten zitten is er nog
            // een volgende pagina.
            this.designsPage = this.designsPageTemplate.startAfter(last.$snap).limit(this.pageSize + 1);
            this.loadInspirations();
        },

        resetSearch () {
            this.firstInspiration = null;
            this.designsPage = this.designsPageTemplate;
            this.loadInspirations();
        },

        /**
         * Haal 1 pagina op met resultaten (na scrollen)
         * @return {Promise<void>}
         */
        async loadInspirations () {
            const documentSnapshots = await this.designsPage.get();
            this.designs = documentSnapshots.docs.map(design => design);

            // Eeste keer het eerste item onthouden
            // Dit dient voor paginering (eerste pagina herkennen)
            if (!this.firstInspiration && this.designs.length) {
                this.firstInspiration = this.designs[0];
            }

            this.hasPrev = this.designs.length && this.firstInspiration?.id !== this.designs[0].id;
            this.hasNext = this.designs.length > this.pageSize;

            // Verwijder laatste item als het enkel voor paginering was opgehaald
            if (this.hasNext) {
                this.designs.pop();
            }

            // Maak designs op voor weergave
            this.designs = this.designs.map(design => ({...design.data(), id: design.id, $snap: design}));
        },

        /**
         * Stel objecten in voor infinite scroll & live update
         * @return {Promise<void>}
         */
        initiateInspirations () {
            // Stel de query in voor het ophalen van 1 "pagina" aan designs
            this.designsPageTemplate = refInspirations
                .orderBy('orderBy', 'asc')
                .limit(this.pageSize + 1);

            this.designsPage = this.designsPageTemplate;
        },
    },
};
</script>

<style scoped lang="scss">

</style>
