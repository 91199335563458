<template>
    <v-dialog v-model="isVisible" persistent light max-width="450">
        <template #activator="{ on, attrs }">
            <slot :attrs="attrs" :on="on">
                <v-btn v-bind="attrs" v-on="on" class="boxed-icon" color="primary" tile elevation="0">
                    {{ $t('global.delete') }}
                    <v-icon right>mdi-trash-can-outline</v-icon>
                </v-btn>
            </slot>
        </template>
        <v-card>
            <v-card-title class="headline primary">{{ $t('dashboard_inspiration_delete.headline') }}</v-card-title>
            <v-card-text class="tiemposText mt-4">
                {{ $t('dashboard_inspiration_delete.explanation', [dataInspiration.name]) }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mr-4 mb-2" @click="isVisible = false">{{ $t('global.cancel') }}</v-btn>
                <v-btn color="primary" class="boxed-icon pr-0 mr-4 mb-2" tiles elevation="0" @click="removeDesign">
                    {{ $t('global.delete') }}
                    <v-icon right>mdi-trash-can-outline</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { toggleable } from '@/mixins/toggleable';
import { removeFirestoreInspirationsAndRemoveFiles } from '@/firebase/firestore/inspirations';

export default {
    name: 'InspirationDeleteConfirmDialog',

    mixins: [toggleable],

    props: {
        // Data van het design
        dataInspiration: {
            type: Object,
            required: true,
        },
        // De firestore referentie (nodig voor verwijderen)
        refInspiration: {
            type: Object,
            required: true,
        },
    },

    methods: {
        async removeDesign () {
            if (!this.refInspiration) {
                this.isVisible = false;

                // Item bestaat niet, maar is dus wel weg.
                // Vandaar event removed, met een indicator dat het een
                // mislukte remove actie was.
                this.$emit('removed', [false]);
            }

            await removeFirestoreInspirationsAndRemoveFiles(this.refInspiration);
            this.$emit('removed', [true]);
        },
    },
};
</script>

<style scoped>

</style>
