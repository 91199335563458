<template>
    <v-data-table
        :headers="headers"
        :items="designs"
        :expanded.sync="expanded"
        show-expand
        single-expand
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
        item-key="name">

        <template v-slot:item.files="{ item }">
            <v-img :src="item.files[0].thumb" :lazy-src="item.files[0].lazy" max-width="25" max-height="25"/>
        </template>
        <template v-slot:item.timestamp="{ item }">{{ item.timestamp | formatDateTime }}</template>
        <template v-slot:item.id="{ item }">
            <inspiration-delete-confirm-dialog :data-inspiration="item" :ref-inspiration="item.$snap.ref" v-slot="{ on, attrs }" @removed="$emit('updated')">
                <v-btn v-bind="attrs" v-on="on" color="primary" circle icon tile elevation="0">
                    <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
            </inspiration-delete-confirm-dialog>
            <inspiration-add-dialog v-slot="{ on, attrs }" :user="user" :inspiration-id="item.id" @updated="$emit('updated')">
                <v-btn v-bind="attrs" v-on="on" color="primary" circle icon tile elevation="0">
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
            </inspiration-add-dialog>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                <v-card flat>
                    <v-card-text>
                        {{ $t('dashboard_inspiration.surface') }}: {{ item.surface || $t('global.not_specified') }}<br>
                        {{ $t('dashboard_inspiration.subcollection') }}: {{ item.subcollection || $t('global.not_specified') }}
                    </v-card-text>
                </v-card>

                <v-row v-if="item.files.length">
                    <v-col class="d-flex child-flex " cols="12">
                        <v-card v-for="(file, i) in item.files.filter(f => $isImageType(f.type))" :key="i">
                            <v-card-text>
                                <v-img :src="file.thumb || file.url" :lazy-src="file.lazy || file.thumb" max-width="75" max-height="75"/>
                            </v-card-text>
                            <v-card-title>{{ file.meta.brickColor }}</v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </td>
        </template>
    </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateTime } from '@/utils/vue-filters';
import InspirationDeleteConfirmDialog from '@/components/dialogs/InspirationDeleteConfirmDialog';
import InspirationAddDialog from '@/components/dialogs/InspirationAddDialog';
import { updateFirestoreInspiration } from '@/firebase/firestore/inspirations';

export default {
    name: 'InspirationalDesignListTabular',

    components: {InspirationAddDialog, InspirationDeleteConfirmDialog},

    props: {
        showImage: {
            type: Boolean,
            default: false,
        },
        designs: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapGetters('authentication', {
            user: 'getUser',
        }),
    },

    filters: {
        formatDateTime: formatDateTime,
    },

    data: function () {
        return {
            search: '',
            expanded: [],
            headers: [
                {
                    text: this.$t('dashboard_inspiration.design_name'),
                    align: 'left',
                    value: 'name',
                },
                {
                    text: this.$t('dashboard_inspiration.design_collection'),
                    align: 'left',
                    value: 'collection',
                },
                {
                    text: this.$t('dashboard_inspiration.design_order'),
                    align: 'left',
                    value: 'orderBy',
                },
                {
                    text: this.$t('dashboard_inspiration.design_created'),
                    align: 'left',
                    value: 'timestamp',
                },
                {
                    text: this.$t('dashboard_inspiration.design_actions'),
                    align: 'right',
                    value: 'id',
                },
            ],
        };
    },

    created () {
        this.showImage && this.headers.unshift({
            text: '',
            align: 'left',
            value: 'files',
        });
    }
};
</script>

<style scoped>

</style>Yy
