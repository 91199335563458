var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.designs,"expanded":_vm.expanded,"show-expand":"","single-expand":"","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","item-key":"name"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.files",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.files[0].thumb,"lazy-src":item.files[0].lazy,"max-width":"25","max-height":"25"}})]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.timestamp)))]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('inspiration-delete-confirm-dialog',{attrs:{"data-inspiration":item,"ref-inspiration":item.$snap.ref},on:{"removed":function($event){return _vm.$emit('updated')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","circle":"","icon":"","tile":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)}),_c('inspiration-add-dialog',{attrs:{"user":_vm.user,"inspiration-id":item.id},on:{"updated":function($event){return _vm.$emit('updated')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","circle":"","icon":"","tile":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('dashboard_inspiration.surface'))+": "+_vm._s(item.surface || _vm.$t('global.not_specified'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('dashboard_inspiration.subcollection'))+": "+_vm._s(item.subcollection || _vm.$t('global.not_specified'))+" ")])],1),(item.files.length)?_c('v-row',[_c('v-col',{staticClass:"d-flex child-flex ",attrs:{"cols":"12"}},_vm._l((item.files.filter(function (f) { return _vm.$isImageType(f.type); })),function(file,i){return _c('v-card',{key:i},[_c('v-card-text',[_c('v-img',{attrs:{"src":file.thumb || file.url,"lazy-src":file.lazy || file.thumb,"max-width":"75","max-height":"75"}})],1),_c('v-card-title',[_vm._v(_vm._s(file.meta.brickColor))])],1)}),1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }